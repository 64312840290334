import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [

  { path: '/404', name: '404', component: () => import('../components/Error404')  },
  { path: '*', redirect: '/'  },
  // { path: '', name: 'Home', component: () => import(/* webpackChunkName: "general" */ '../views/Home.vue') },



  { path: '/about', name: 'About', component: () => import(/* webpackChunkName: "general" */ '../views/About.vue') },
  { path: '/', name: 'Home', component: () => import(/* webpackChunkName: "general" */ '../components/Home') },
  { path: '/welcome', name: 'Welcome', component: () => import(/* webpackChunkName: "general" */ '../components/Welcome') },


  { path: '/my-dashboard', name: 'my-dashboard', component: () => import(/* webpackChunkName: "dashboard" */ '../components/MyDashboard.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/my-trucks', name: 'MyTrucks', component: () => import(/* webpackChunkName: "dashboard" */ '../components/MyTrucks.vue'), meta: { requiresAuth: true }, props: true  },


  { path: '/sign-in', name: 'SignIn', component: () => import(/* webpackChunkName: "signing" */ '../components/SignIn'), props: true },
  { path: '/sign-up', name: 'SignUp', component: () => import(/* webpackChunkName: "signing" */ '../components/SignUp'), props: true },
  { path: '/square-new-checkout', name: 'SquareNewCheckout', component: () => import(/* webpackChunkName: "signing" */ '../components/SquareNewCheckout'), props: true },

  { path: '/my-details', name: 'MyDetails', component: () => import(/* webpackChunkName: "profile" */ '../profile/MyDetails.vue'), meta: { requiresAuth: true }, props: true  },


  { path: '/my-fleets', name: 'MyFleets', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/MyFleets.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-dashboard', name: 'FleetDashboard', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetDashboard.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-trucks', name: 'FleetTrucks', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetTrucks.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-edit-sms', name: 'FleetEditSms', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetEditSms.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-custom-checklist', name: 'FleetCustomChecklist', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetCustomChecklist.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-truck-log', name: 'FleetTruckLog', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetTruckLog.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-truck-revisions', name: 'FleetTruckRevisions', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetTruckRevisions.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-archived-trucks-logs', name: 'FleetArchivedTrucksLogs', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetArchivedTrucksLogs'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-truck-daily-tasks', name: 'FleetTruckDailyTasks', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetTruckDailyTasks'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-archived-daily-tasks', name: 'FleetArchivedDailyTasks', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetArchivedDailyTasks'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-truck-uploads', name: 'FleetTruckUploads', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetTruckUploads'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-truck-dates', name: 'FleetTruckDates', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetTruckDates'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-truck-information', name: 'FleetTruckInformation', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetTruckInformation.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/fleet-service-schedules', name: 'FleetServiceSchedules', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetServiceSchedules'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-staff', name: 'FleetStaff', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetStaff'), meta: { requiresAuth: true }, props: true },
  { path: '/add-staff', name: 'AddStaff', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/AddStaff'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-approve', name: 'FleetApprove', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetApprove'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-sms-revisions', name: 'FleetSmsRevisions', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetSmsRevisions'), meta: { requiresAuth: true }, props: true },
  { path: '/fleet-all-reports', name: 'FleetAllReports', component: () => import(/* webpackChunkName: "fleet" */ '../fleet/FleetAllReports'), meta: { requiresAuth: true }, props: true },




  { path: '/truck-information', name: 'TruckInformation', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/TruckInformation.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/add-edit-trucks-log', name: 'AddEditTrucksLog', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/AddEditTrucksLog'), meta: { requiresAuth: true }, props: true },
  { path: '/add-edit-task', name: 'AddEditTask', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/AddEditTask'), meta: { requiresAuth: true }, props: true },
  { path: '/add-edit-date', name: 'AddEditDate', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/AddEditDate'), meta: { requiresAuth: true }, props: true },
  { path: '/truck-edit-sms', name: 'TruckEditSms', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/TruckEditSms'), meta: { requiresAuth: true }, props: true },
  { path: '/truck-log', name: 'TruckLog', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/TruckLog.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/archived-trucks-logs', name: 'ArchivedTrucksLogs', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/ArchivedTrucksLogs'), meta: { requiresAuth: true }, props: true },
  { path: '/custom-checklist', name: 'CustomChecklist', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/CustomChecklist.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/truck-revisions', name: 'TruckRevisions', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/TruckRevisions.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/prestart-checklist', name: 'PrestartChecklist', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/PrestartChecklist.vue'), meta: { requiresAuth: true }, props: true  },
  { path: '/service-schedules', name: 'ServiceSchedules', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/ServiceSchedules'), meta: { requiresAuth: true }, props: true },
  { path: '/startup-checklists', name: 'StartupChecklists', component: () => import(/* webpackChunkName: "sms" */ '../smscomponents/StartupChecklists'), meta: { requiresAuth: true }, props: true },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
