<template>
  <main id="app" class="">

    <nprogress-container></nprogress-container>

        <div class="header">
          <router-link to="/">
            <img class="logo" src="./assets/img/header-logo.jpg" alt="Safe Truckie" />
          </router-link>
          <mobile-nav></mobile-nav>
        </div>

        <div class="header-spacer"></div>

        <router-view></router-view>

        <!-- <div class="notification force-refresh" @click="refreshApp" v-show="showRefreshButton">
          New content available!<br>Click to refresh.
        </div> -->

        <!-- <back-to-top bottom="60px" right="20px" visibleoffset="200">
          <div class="btt">
            <font-awesome-icon icon="chevron-up" />
          </div>
        </back-to-top> -->



  </main>
</template>




<script>
  
  // import Header from './components/Header.vue';
  import MobileNav from './components/MobileNav.vue';
  import NprogressContainer from 'vue-nprogress/src/NprogressContainer';

  import Vue from 'vue';





  // Custom Directive to close modals on click outside
  let handleOutsideClick;
  Vue.directive('closable', {
    bind (el, binding, vnode) {
      // console.log(vnode);
      // Here's the click/touchstart handler
      handleOutsideClick = (e) => {
        e.stopPropagation()
        // Get the handler method name and the exclude array from the object used in v-closable
        const { handler, exclude } = binding.value
        // This variable indicates if the clicked element is excluded
        let clickedOnExcludedEl = false
        exclude.forEach(refName => {
          // We only run this code if we haven't detected any excluded element yet
          if (!clickedOnExcludedEl) {
            // Get the element using the reference name
            const excludedEl = vnode.context.$refs[refName]
            // See if this excluded element is the same element the user just clicked on
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        })
        // We check to see if the clicked element is not the modal element and not excluded
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          // If the clicked element is outside the modal and not the button, then call the outside-click handler from the same component this directive is used in
          vnode.context[handler]()
        }
      }
      // Register click/touchstart event listeners on the whole page
      document.addEventListener('click', handleOutsideClick)
      document.addEventListener('touchstart', handleOutsideClick)
    },
    unbind () {
      // If the element that has v-closable is removed, then unbind click/touchstart listeners from the whole page
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  });



  export default {
    components: {
      NprogressContainer,
      MobileNav,
    },
    data() {
      return {
        
      }
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      }
    },
    methods: {
      setUser: function() {
        this.$store.dispatch('setUser');
      },
    },
    created() {

      this.setUser();
      // this.setIsAdmin();
      // this.$store.dispatch('SETSUBSCRIPTIONINFO');

      // Listen for swUpdated event and display refresh snackbar as required.
      // document.addEventListener('swUpdated', this.showRefreshUI, { once: true });

      // Refresh all open app tabs when a new service worker is installed.
      // navigator.serviceWorker.addEventListener('controllerchange', () => {
      //   if (this.refreshing) return;
      //   this.refreshing = true;
      //   window.location.reload();
      // });


    }


  }


</script>





<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
