<template>
  <div id="mobilenavwrapper">

    <div class="nav-icon-wrapper">
      <!-- <div class="nav-icon-link" v-if="user" @click="toTimer">
        <img v-if="isRunning" src="@/assets/clock5.gif" />
        <img v-if="!isRunning && isOpen" src="@/assets/clock-black.jpg" />
      </div> -->
      <!-- <router-link v-if="myCompanies.length" class="nav-icon-link" to="my-companies">
        <font-awesome-icon icon="briefcase" />
      </router-link> -->
      <router-link data-v-step="1" class="nav-icon-link" :class="{offline:!onlineStatus}" to="my-dashboard" v-if="user">
        <font-awesome-icon icon="home" />
        <button v-show="!onlineStatus" class="delete is-small"></button>
      </router-link>
    </div>
    <div data-v-step="2" id="nav-icon" ref="nav-icon" v-bind:class="{ active: isActive }" v-on:click="toggle">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div v-if="seen" class="dropmenu" v-closable="{exclude: ['nav-icon'],handler: 'closeModal'}">
      <ul>
        <!-- <li><a>User: {{user ? user.uid : 'No user'}}</a></li> -->
        <li>
          <router-link to="/">Home Page</router-link>
        </li>
        <li>
          <router-link to="my-dashboard" v-if="user">My Dashboard</router-link>
        </li>
        <!-- <li>
          <router-link to="pricing" v-if="!user">SafeTruckie Pricing</router-link>
        </li>
        <li><a @click.prevent="showSignUpModal = true" v-if="!user">Create an account</a></li>
        <li>
          <router-link to="explore-features">Explore Features</router-link>
        </li>
        <li>
          <router-link to="support">Help &amp; Support</router-link>
        </li>
        <li>
          <router-link to="useful-links">Useful Links</router-link>
        </li> -->
        <li>
          <router-link to="sign-in" v-if="!user">Sign-in</router-link>
        </li>
        <li><a v-on:click="signOut" v-if="user">Sign-out</a></li>
      </ul>
    </div>

    <div class="clear"></div>

    <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{'is-active':showSignUpModal}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Join SafeTruckie</p>
        </header>
        <section class="modal-card-body">
          <div class="buttons has-text-centered">
            <a class="button is-primary" @click.prevent="toSmsSignUp">Get an SMS from $249/year</a>
            <a class="button is-info" @click.prevent="toSignUp">Or join for Free as a Crew</a>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="close">Close</button>
        </footer>
      </div>
    </div>

  </div>

</template>

<script>

import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/functions';
//import { store } from '../store/store';
import { db } from '../main.js';


export default {
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    // isUserAdmin() {
    //   return this.$store.getters.getIsUserAdmin;
    // },
    // adminStatus() {
    //   return this.isUserAdmin ? 'Admin' : 'Crew'
    // },
    // getUserName() {
    //   let user = this.$store.getters.getUser;
    //   console.log(user);
    //   if (user) {
    //     if (user.displayName) {
    //       let firstName = user.displayName.split(" ");
    //       return 'Hi ' + firstName[0];
    //     } else {
    //       return 'Hi';
    //     }
    //   }

    // },
    // statusMsg() {
    //   let status = this.$store.getters.getIsUserAdmin;
    //   const statusMsg = (status ? "You are an Admin" : "You are crew");
    //   return statusMsg;
    // },
    // isRunning() {
    //   return this.$store.getters.getIsTimerRunning;
    // },
    // isOpen() {
    //   return this.$store.getters.getIsTimerOpen;
    // },
    // userTest() {
    //   return this.$store.getters.getUserTestData;
    // },
    // userTestName() {
    //   return this.userTest ? this.userTest.slice(0, 7) : '!!!'
    // },
    // myCompanies() {
    //   return this.$store.getters.getMyCompanies;
    // }
  },

  data: function() {
    return {
      isActive: false,
      seen: false,
      onlineStatus: false,
      browser: '',
      showSignUpModal: false,
      firebaseid: '',
      firebasedisplayname: '',
      hasData: false,
      // firebaseid: '',
      //userProfile: [],
      myUsername: '',
    }
  },

  mounted() {

    // console.log('mobileNav', this.$store);

    //var self = this;
    //console.log(navigator.onLine);
    //window.addEventListener('load', () => {
    //console.log(navigator.onLine);
    // 1st, we set the correct status when the page loads
    //navigator.onLine ? this.showStatus(true) : this.showStatus(false);
    navigator.onLine ? this.onlineStatus = true : this.onlineStatus = false;

    // now we listen for network status changes
    window.addEventListener('online', () => {
      this.onlineStatus = true;
    });

    window.addEventListener('offline', () => {
      this.onlineStatus = false;
    });

    // this.browser = this.$browserDetect.meta.name;
    // this.$store.dispatch('SETONLINESTATUS');
    // this.$store.dispatch('GETISRUNNING');
    // this.$store.dispatch('GETISTIMEROPEN');
    // this.$store.dispatch('GETTIMERSTART');
    // this.$store.dispatch('GETTIMERSTOP');
    // this.$store.dispatch('GETTIMERTOTAL');

    this.checkData();

  },

  watch: {
    $route() {
      // console.log(to, from);
      this.isActive = false;
      this.seen = false
    }
  },
  methods: {

    checkData: function() {
      let self = this;
      if (self.user) {
        db.collection('userdata').doc(self.user.uid).get().then(function(doc) {
          // self.myUsername = doc.data().username ? doc.data().username.slice(0,7) : '!!!'
          self.myUsername = doc.data().username ? '' : '!!!'
        });
      }
    },

    signOut: function() {

      var self = this;
      var addRevision = firebase.functions().httpsCallable('addRevision');
      addRevision({
        userID: self.user.uid,
        userName: self.user.displayName,
        description: 'A user has signed out.',
      });

      var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
      userHasEngaged({
        userID: self.user.uid,
        userName: self.user.displayName,
        message: 'A user has signed out of SafeTruckie.',
        subject: 'A user has signed out'
      });

      firebase.auth().signOut().then(() => {
        this.$store.dispatch('resetStates');
        this.$router.replace('sign-in');
      });

    },

    // toTimer: function() {
    //   this.$router.push({
    //     name: 'sea-time',
    //     params: {
    //       loadTab: 'seatime'
    //     }
    //   });
    // },

    close: function() {
      this.showSignUpModal = false;
    },

    // toSmsSignUp: function() {
    //   this.showSignUpModal = false;
    //   this.$router.push({ name: 'smsSignup' });
    // },

    // toSignUp: function() {
    //   this.showSignUpModal = false;
    //   this.$router.push({
    //     name: 'signUp',
    //     params: {
    //       planType: 'free',
    //       maxVessels: 0
    //     }
    //   });
    // },

    toggle: function() {
      this.isActive = !this.isActive;
      this.seen = !this.seen;
    },

    closeModal: function() {
      this.isActive = !this.isActive;
      this.seen = !this.seen;
    },

  },


};
</script>
<style lang="scss">
@import "././node_modules/bulma/sass/utilities/_all.sass";

#mobilenavwrapper {
  margin-right: 45px;
  float: right;
  font-size: 25px;
  margin-top: 11px;

  .nav-icon-link {
    display: inline-block;
    display: table-cell;
    vertical-align: middle;
    padding-right: 20px;
    color: #245e8b;

    img {
      height: 28px;
      margin: 0;
    }
  }

  .user-status {
    position: absolute;
    right: 118px;
    top: 19px;
    font-size: 16px;
    color: #fbc51b;
    // @include mobile {
    //   display: none;
    // }
  }
}

.menu-fish {
  position: absolute;
  top: 12px;
  right: 120px;
  cursor: pointer;
  font-size: 23px;
  color: #245e8b;
  transition: 0.3s;

  &:hover {
    color: #1c99ff;
  }
}

.menu-user {
  position: absolute;
  top: 12px;
  right: 70px;
  cursor: pointer;
  font-size: 23px;
  color: #245e8b;
  transition: 0.3s;

  &:hover {
    color: #1c99ff;
  }

  .delete {
    position: absolute;
    bottom: -3px;
    right: -8px;
    background: #f95b7a;

    &:hover {
      background-color: #f95b7a;
    }
  }
}

.menu-facebook {
  position: absolute;
  top: 11px;
  right: 115px;
  cursor: pointer;
  font-size: 23px;
  color: #245e8b;
  transition: 0.3s;

  &.noUser {
    right: 80px;
  }

  &:hover {
    color: #1c99ff;
  }
}

.menu-timer-icon {
  position: absolute;
  top: 17px;
  right: 92px;
  cursor: pointer;
  transition: 0.3s;

  img {
    width: 20px;
    height: 20px !important;
    float: none;
    margin: 0;
  }
}

.menu-open-icon {
  position: absolute;
  top: 20px;
  right: 140px;
  cursor: pointer;
  transition: 0.3s;
  color: red;

  &.open {
    color: green;
  }
}

@media only screen and (max-width: 479px) {

  #mobilenavwrapper {
    margin-right: 30px;
    margin-top: 14px;
    padding-right: 15px;
    .nav-icon-link {
      font-size: 21px;
      padding-right: 12px;
      img {
        height: 24px;
        margin-top: 3px;
      }
      &:last-of-type {
        padding-right: 18px;
      }
    }
  }
}


</style>