import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '../main.js';

import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/firestore';

Vue.use(Vuex)

// export const store = new Vuex.Store({
export default new Vuex.Store({

  // STATE ===============================================================================================
  state: {

    user: null,
    subscriptionInfo: {},

    myTrucks: [],
    myCurrentTruckIDs: [],
    myActiveTrucks: [],
    myActiveTruckIDs: [],
    myFleets: [],
    currentFleetName: '',
    currentFleetID: '',
    currentFleet: {
      name: '',
      id: ''
    },
    currentFleetTruck: {},
    currentLocation: '',
    smsStatus: {
      overview: false,
      duty: false,
      master: false,
      designated: false,
      hazards: false,
      smscompanyhazards: false,
      defects: false,
      inductions: false,
      smscontacts: false,
      companycontacts: false,
      smspolicies: false,
      companypolicies: false,
      smssops: false,
      companysops: false,
      smseops: false,
      companyeops: false,
      smsmaintenance: false,
      companymaintenance: false,
      smsstartups: false,
      companystartups: false,
      smsactivities: false,
      companyactivities: false,
      smsshutdowns: false,
      companyshutdowns: false,
      vesselinductionitems: false,
      companyinductionitems: false,
      auditorFeedback: false,
      auditorFeedbackCount: 0,
      amsaAuditsPending: 0,
    },
    fleetStatus: {
      policies: 0,
      sops: 0,
      eops: 0,
      contacts: 0,
      inductions: 0,
      maintenance: 0,
      startups: 0,
      activities: 0,
      shutdowns: 0,
      count: false,
      maxTrucks: 0,
    },
    selectedTruck: '',
    currentTruckName: '',
    currentTruckID: '',
    currentTruckUVI: '',
    currentTruckPaidFor: false,
    currentTruckStatus: 'free',


  },

  getters: {

    getUser: state => {
      return state.user;
    },
    getSubscriptionInfo: state => {
      return state.subscriptionInfo;
    },
    getUserRole: state => {
      return state.subscriptionInfo.userrole;
    },
    getMyActiveTrucks: state => {
      return state.myActiveTrucks;
    },
    getMyActiveTruckIDs: state => {
      return state.myActiveTruckIDs;
    },
    getMyFleets: state => {
      return state.myFleets;
    },
    getFleetStatus: state => {
      return state.fleetStatus;
    },
    getSmsStatus: state => {
      return state.smsStatus;
    },
    getCurrentFleet: state => {
      return state.currentFleet;
    },
    getCurrentFleetTruck: state => {
      return state.currentFleetTruck;
    },
    getCurrentTruck: state => {
      return state.currentTruck;
    },
    getCurrentTruckName: state => {
      return state.currentTruckName;
    },
    getCurrentTruckID: state => {
      return state.currentTruckID;
    },
    getCurrentTruckUVI: state => {
      return state.currentTruckUVI;
    },
    getCurrentTruckPaidFor: state => {
      return state.currentTruckPaidFor;
    },
    getCurrentTruckStatus: state => {
      return state.currentTruckStatus;
    },

  },

  // MUTATIONS ===============================================================================================
  mutations: {

    LOADDASHBOARD: state => {
      state.stateLoaded = state.myTrucks.length ? true : false;
    },

    SETUSERPROFILE: state => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          if (doc.exists) {
            state.userProfile = doc.data();
          }
        });
      }
    },

    SETUSER: state => {
      state.user = firebase.auth().currentUser;
    },

    UPDATEUSERSTATE: state => {
      var user = firebase.auth().currentUser;
      if (user) {
        // console.log('User:', user);
        db.collection('userdata').doc(user.uid).onSnapshot(function(doc) {
          if (doc.exists) {
            // console.log(doc.data());
            state.isUserAdmin = (doc.data().userrole == 'free' ? false : true);
            state.subscriptionInfo.userrole = doc.data().userrole ? doc.data().userrole : '';
          }
        });
      }
    },

    SETSUBSCRIPTIONINFO: state => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(documentSnapshot) {
          if (documentSnapshot.exists) {
            var data = documentSnapshot.data(); 
            // console.log(data);
            let tempDate = (data.anniversaryDate ? data.anniversaryDate.toDate() : '');
            let stringDate = String(tempDate);
            state.subscriptionInfo.currentMaxTrucks = data.maxTrucks ? data.maxTrucks : 0;
            state.subscriptionInfo.anniversaryDate = stringDate;
            state.subscriptionInfo.userid = data.userid;
            state.subscriptionInfo.nextCharge = (data.nextCharge ? data.nextCharge : 0);
            // state.subscriptionInfo.test = stringDate;
            // state.subscriptionInfo.homeport = data.homeport ? data.homeport : '';
            state.subscriptionInfo.industry = data.industry ? data.industry : '';
            state.subscriptionInfo.userrole =  data.userrole ? data.userrole : '';
            // state.subscriptionInfo.isTrainer = (data.isTrainer ? data.isTrainer : false);
            // state.subscriptionInfo.isAuditor = data.isAuditor ? data.isAuditor : false;
            // state.subscriptionInfo.auditorState = data.auditorState ? data.auditorState : 'Not set';
            // state.subscriptionInfo.doesDiving = (data.doesDiving ? data.doesDiving : false);
            // state.subscriptionInfo.takesPassengers = (data.takesPassengers ? data.takesPassengers : false);
            // state.subscriptionInfo.trainerOrganisation = (data.trainerOrganisation ? data.trainerOrganisation : 'not set');
            // state.subscriptionInfo.trainerDefaultState = (data.trainerDefaultState ? data.trainerDefaultState : false);
            // state.subscriptionInfo.trainerDefaultIndustry = (data.trainerDefaultIndustry ? data.trainerDefaultIndustry : false);
            // state.subscriptionInfo.trainerDefaultIndustry = (data.trainerDefaultIndustry ? data.trainerDefaultIndustry : false);
            state.subscriptionInfo.username = data.username ? data.username : 'No name';
            state.subscriptionInfo.useremail = data.useremail ? data.useremail : '';
            state.subscriptionInfo.userphone = data.userphone ? data.userphone : '';
            state.subscriptionInfo.userstate = data.userstate ? data.userstate : '';
            state.subscriptionInfo.address = data.address ? data.address : '';
            state.subscriptionInfo.emergencyInfo = data.emergencyInfo ? data.emergencyInfo : '';
            state.subscriptionInfo.takenDashboardTour = data.takenDashboardTour ? data.takenDashboardTour : false;
            state.subscriptionInfo.seenAppPrompt = data.seenAppPrompt ? data.seenAppPrompt : false;
            state.subscriptionInfo.seenAppPromptDate = data.seenAppPromptDate ? data.seenAppPromptDate : '';
            state.subscriptionInfo.freeTrial = data.freeTrial ? data.freeTrial : false;
            state.subscriptionInfo.trialEndDate = data.trialEndDate ? data.trialEndDate.toDate() : '';
          }
        });
      }
    },

    MYACTIVETRUCKS: (state) => {
      state.myActiveTrucks = [];
      state.myActiveTruckIDs = [];

      if (state.currentFleet.id) {
        db.collection('fleets').doc(state.currentFleet.id).collection('fleetTrucks').onSnapshot(function(querySnapshot) {
          state.myActiveTrucks = [];
          state.myActiveTruckIDs = [];
          
          querySnapshot.forEach((truck) => {
            db.collection('trucks').doc(truck.id).get().then(function(item) {

              if (item.data().isActive) {
                let tempDate =  item.data().lastStartupDate;
                let finalDate;
                if (typeof tempDate === 'string') {
                  finalDate = tempDate.substr(0, tempDate.indexOf(',')); 
                } else {
                  finalDate = tempDate.toDate();
                }

                state.myActiveTrucks.push({
                  id: item.id,
                  isActive: item.data().isActive,
                  truckName: item.data().truckName,
                  truckUVI: item.data().truckUVI,
                  lastStartupID: item.data().lastStartupID ? item.data().lastStartupID : '',
                  lastStartupDate: finalDate,
                });
                state.myActiveTruckIDs.push(item.id);
              }
            });
          });

        });
      } else {
        console.log('No fleet..');
      }

    },

    SETMYFLEETS: (state) => {
      db.collection('userdata').doc(state.user.uid).collection('myFleets').onSnapshot(function(querySnapshot) {
        state.myFleets = [];
        querySnapshot.forEach(function(doc) {
          state.myFleets.push(doc.data());
        })
      });
    },

    SETCURRENTFLEET: (state, payload) => {
      state.currentFleet.name = payload.fleet.fleetName;
      state.currentFleet.id = payload.fleet.fleetID;
      state.currentFleet.state = payload.fleet.fleetState;
      // store.commit('MYACTIVETRUCKS');
    },


    SETLOCATION: (state, payload) => {
      state.currentLocation = payload;
    },

    SETFLEETSTATUS: state => {

      state.fleetStatus.count = 0;

      if (state.currentFleet) {
        db.collection('fleets').doc(state.currentFleet.id).collection('companyPolicies').onSnapshot(function(querySnapshot) {
            state.fleetStatus.policies = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companySOPS').onSnapshot(function(querySnapshot) {
            state.fleetStatus.sops = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyEOPS').onSnapshot(function(querySnapshot) {
            state.fleetStatus.eops = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyContacts').onSnapshot(function(querySnapshot) {
            state.fleetStatus.contacts = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyInductionItems').onSnapshot(function(querySnapshot) {
            state.fleetStatus.inductions = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyMaintenanceItems').onSnapshot(function(querySnapshot) {
            state.fleetStatus.maintenance = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyStartupItems').onSnapshot(function(querySnapshot) {
            state.fleetStatus.startups = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyActivityItems').onSnapshot(function(querySnapshot) {
            state.fleetStatus.activities = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyShutdownItems').onSnapshot(function(querySnapshot) {
            state.fleetStatus.shutdowns = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyAdditionalItems').onSnapshot(function(querySnapshot) {
            state.fleetStatus.additional = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).collection('companyHazards').onSnapshot(function(querySnapshot) {
            state.fleetStatus.hazards = querySnapshot.size;
            if (querySnapshot.size > 0) state.fleetStatus.count++; 
        });
        db.collection('fleets').doc(state.currentFleet.id).onSnapshot(function(doc) {
            state.fleetStatus.maxTrucks = doc.data().maxTrucks ? doc.data().maxTrucks : 0;
        });
      }

        


    },

    SETSELECTEDTRUCK: (state, payload) => {
      // console.log("INSIDE: ",payload.truckName,payload.truckID,payload.truckUVI);
      state.currentTruckName = payload.truckName;
      state.currentTruckID = payload.truckID;
      state.currentTruckUVI = payload.truckUVI;
      state.currentTruckPaidFor = payload.isPaidFor ? payload.isPaidFor : false;
      state.selectedTruck = {
        truckName: payload.truckName ? payload.truckName : '',
        truckID: payload.truckID ? payload.truckID : '',
        truckUVI: payload.truckUVI ? payload.truckUVI : '',
        isPaidFor: payload.isPaidFor ? payload.isPaidFor : false,
      }
      // db.collection('trucks').doc(payload.truckID).onSnapshot(function(doc) {
      //   state.currentTruckStatus = doc.data().truckStatus ? doc.data().truckStatus : 'free';
      // });
    },

    SETSMSSTATUS: state => {

      // console.log(state.currentTruckID);

      db.collection("trucks").doc(state.currentTruckID).onSnapshot(function(doc) {
          state.smsStatus.overview = doc.data().overviewSavedAt ? true : false;
          state.smsStatus.duty = doc.data().dutyCareAcceptedAt ? true : false;
          state.smsStatus.master = doc.data().masterStatementSavedAt ? true : false;
          state.smsStatus.designated = doc.data().designatedStatementSavedAt ? true : false;
      });

      // hazards
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetHazards')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smscompanyhazards = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsHazards')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.hazards = (querySnapshot.size > 0 ? true : false)
        });

      // defects
      db.collection("trucks").doc(state.currentTruckID).collection('smsDefects')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.defects = (querySnapshot.size > 0 ? true : false)
        });

      // current defects
      // db.collection("trucks").doc(state.currentTruckID).collection('smsHazards')
      //   .onSnapshot(function(querySnapshot) {
      //     state.smsStatus.hazards = (querySnapshot.size > 0 ? true : false)
      //   });

      // inductions
      db.collection("trucks").doc(state.currentTruckID).collection('smsInductionItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.inductions = (querySnapshot.size > 0 ? true : false)
        });



      // contacts
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetContacts')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companycontacts = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsContacts')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smscontacts = (querySnapshot.size > 0 ? true : false)
        });


      // policies
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetPolicies')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companypolicies = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsPolicies')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smspolicies = (querySnapshot.size > 0 ? true : false)
        });


      // SOPS
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetSOPS')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companysops = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsSOPS')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smssops = (querySnapshot.size > 0 ? true : false)
        });



      //EOPS
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetEOPS')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companyeops = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsEOPS')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smseops = (querySnapshot.size > 0 ? true : false)
        });



      // maintenance
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetMaintenanceItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companymaintenance = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsMaintenanceItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smsmaintenance = (querySnapshot.size > 0 ? true : false)
        });


      

      //startups
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetStartupItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companystartups = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsStartupItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smsstartups = (querySnapshot.size > 0 ? true : false)
        });


      

      //activity notes
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetActivityItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companyactivities = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsActivityItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smsactivities = (querySnapshot.size > 0 ? true : false)
        });

      

      //shutdowns
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetShutdownItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companyshutdowns = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsShutdownItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.smsshutdowns = (querySnapshot.size > 0 ? true : false)
        });

      

      // crew inductions
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetInductionItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companyinductionitems = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsTruckInductionItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.vesselinductionitems = (querySnapshot.size > 0 ? true : false)
        });

      

      // passenger inductions
      db.collection("trucks").doc(state.currentTruckID).collection('smsFleetPassengerInductionItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.companypassengerinductionitems = (querySnapshot.size > 0 ? true : false)
        });
      db.collection("trucks").doc(state.currentTruckID).collection('smsPassengerInductionItems')
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.passengerinductionitems = (querySnapshot.size > 0 ? true : false)
        });


      

      //auditor feedback
      db.collection("trucks").doc(state.currentTruckID).collection('auditorFeedback').where("feedbackSeen", "==", false)
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.auditorFeedback = querySnapshot.size > 0 ? true : false;
          state.smsStatus.auditorFeedbackCount = querySnapshot.size;
        });

      
      // pending amsa audits
      db.collection("trucks").doc(state.currentTruckID).collection('amsaAudits').where("auditStatus", "==", "pending")
        .onSnapshot(function(querySnapshot) {
          state.smsStatus.amsaAuditsPending = querySnapshot.size;
        });

    },








  },

  // ACTIONS ===============================================================================================
  actions: {

    LOADDASHBOARD: context => {
      context.state.stateLoaded = false;
      setTimeout(() => {
        context.commit('LOADDASHBOARD')
      }, 3000)
    },
    setUser: context => {
      context.commit('SETUSER');
    },
    SETUSERPROFILE: context => {
      context.commit('SETUSERPROFILE');
    },
    updateUserState: context => {
      context.commit('UPDATEUSERSTATE');
    },
    SETSUBSCRIPTIONINFO: context => {
      context.commit('SETSUBSCRIPTIONINFO');
    },
    UPDATEMYFLEETS: context => {
      context.commit('SETMYFLEETS');
    },
    SETCURRENTFLEET: (context, payload) => {
      context.commit('SETCURRENTFLEET',payload);
    },
    SETMYACTIVETRUCKS: context => {
      context.commit('MYACTIVETRUCKS');
    },
    UPDATELOCATION: (context, payload) => {
      context.commit('SETLOCATION',payload);
    },
    UPDATEFLEETSTATUS: context => {
      context.commit('SETFLEETSTATUS')
    },
    UPDATESELECTEDTRUCK: (context, payload) => {
      context.commit('SETSELECTEDTRUCK',payload);
    },
    UPDATESMSSTATUS: context => {
      context.commit('SETSMSSTATUS')
    },

  },

  // MODULES ===============================================================================================
  modules: {

  }

})
